import React, { useState, useEffect } from "react"
import { isBlank } from "../utils/isBlank"

export default function Background({ children }) {
  const [isWindowBlank, setWindowBlank] = useState(false)

  useEffect(() => {
    setWindowBlank(isBlank(window))
  })
  return (
    <div>
      {!isWindowBlank && <div className="bg-primary h-36 md:h-56"></div>}
      <div className="bg-transparent md:flex md:justify-center md:content-start text-xl">
        {children}
      </div>
    </div>
  )
}
